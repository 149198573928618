@import "../../App.scss";

.card {
    width: calc(300px + 2 * 0.5rem);
    display: flex;
    align-items: right;
    padding: 0.5rem;
    border: 1px solid rgba(0, 0, 0, 0.125);

    .card_body {
        width: 300px;
        flex-direction: column;
        display: flex;
        justify-content: space-around;
        margin: 0 0.8rem;
        font-family: gotham-bold;

        .category {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            letter-spacing: 0.05rem;
            color: $gray60;
        }

        .title {
            font-size: 18px;
            font-family: Gotham-Bold;
            line-height: 16px;
            line-height: 22px;
            white-space: pre-wrap;
        }

        .card_expiration {
            display: inline-block;
            font-size: 11px;
            font-family: Gotham-Medium;
            line-height: 2;
            letter-spacing: 0.05rem;
            color: $gray40;
        }
    }
}
