@import "../easyui.scss";

@mixin lang_selector {
    :global
        .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        background-color: $ck-red !important;
        color: $ck-red !important;
    }

    :global .ant-select:not(.ant-select-open) .ant-select-selector {
        border: none !important;
        box-shadow: none !important;
        background-color: $light-grey !important;
    }

    :global .ant-select .ant-select-selector {
        border: none !important;
        box-shadow: none !important;
        background-color: white !important;
    }

    :global .ant-select {
        border: 2px solid white !important;
    }

    :global .ant-select.ant-select-open {
        border: 2px solid black !important;
    }

    :global .ant-select-selection-item {
        display: flex;
        align-items: center;
        border: 2px solid black !important;
        background-color: white !important;
    }
}
