@import "../../App.scss";
@import "../../easyui-components/easyui.scss";

// Ant Design overrule
:global .ant-menu-root > .ant-menu-item:not(:last-child),
:global .ant-menu-root > .ant-menu-submenu:not(:last-child) {
    border-bottom: 1px solid #f0f0f0;
}
:global .ant-menu > .ant-menu-submenu > .ant-menu-submenu-title,
:global .ant-menu:not(.ant-menu-sub) > .ant-menu-item {
    padding-left: 15px !important;
}
:global .ant-menu-sub .ant-menu-item {
    font-family: Gotham-Medium;
    padding-left: 20px !important;
    font-size: 11px !important;
    height: 30px !important;
    line-height: 30px !important;
}
:global .ant-layout {
    background: white;
}
:global .ant-layout-sider-children {
    border-right: 1px solid #f0f0f0;
}

// Side menu re-branding
$accent-color: $ck-red;
:global .ant-menu-submenu-selected,
:global .ant-menu-item-selected,
:global .ant-menu-light .ant-menu-submenu-title:hover,
:global .ant-menu-light .ant-menu-item:hover,
:global .ant-menu-light .ant-menu-item:hover,
:global
    .ant-menu-light
    .ant-menu-submenu-title:hover
    > .ant-menu-submenu-title
    > .ant-menu-submenu-arrow {
    color: $accent-color;
}

:global .ant-menu-inline {
    border: none;
}

:global .ant-menu-light .ant-menu-submenu-active {
    color: $ck-red !important;
}

:global .ant-menu-inline .ant-menu-item::after {
    border-color: $accent-color;
}

:global .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: rgba($accent-color, 0.1);
}

:global .ant-menu-item:active,
:global .ant-menu-submenu-title:active {
    background-color: rgba($accent-color, 0.1);
}

:global .ant-menu-item-active {
    color: $ck-red !important;
}

:global
    .ant-menu-submenu-selected
    > .ant-menu-submenu-title
    > .ant-menu-submenu-arrow {
    color: $accent-color;
}

:global
    .ant-menu-submenu:hover
    > .ant-menu-submenu-title
    > .ant-menu-submenu-arrow {
    color: black;
}

.home_container {
    display: grid;
    height: 100vh;
    grid-template-columns: $side-menu-width 1fr;
    grid-template-rows: auto 1fr;
}

.logo {
    height: 20px;
    margin: 10px;
    display: flex;
    line-height: 23px;

    img {
        height: 100%;
        margin-right: 10px;
    }
}

.top_header {
    width: 100%;
    grid-column: 1 / 3;
    grid-row: 1;
}

.content {
    box-sizing: border-box;
    overflow: auto;
}

.sider {
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 100;
    font-family: Gotham-Medium;
    font-size: 12px;
    text-transform: uppercase;
    background: white;
    border-right: 1px solid #eeeeee;

    @media (max-width: $tablet-breakpoint) {
        overflow: visible;
    }

    ul {
        border-top: 1px solid #eeeeee;

        & > li,
        & > li > div {
            font-size: 12px !important;
        }
    }
}

.layout {
    margin-left: 200px;
    @media (max-width: $tablet-breakpoint) {
        margin-left: 0;
    }
    font-family: Gotham-Medium;
}
