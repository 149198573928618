@import "../../App.scss";
@import "../../easyui-components/easyui.scss";

.bucket_page {
    @include easy-ui-table;
    @include easy-ui-pagination;
    @include home-nested-page;
}
.translatable_contents {
    display: flex;
    flex-direction: row;
}

.translatable_content {
    padding: 0 16px 16px 0;
    display: flex;
    flex-direction: column;

    &:not(:last-child) {
        border-right: 1px solid #e0e0e0;
    }

    .input_padding {
        padding: 16px 0;
    }
}

.input_holder {
    width: 350px;
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    font-weight: bold;
}

.title_input {
    margin-bottom: 16px;
}

.title_and_button {
    display: flex;
    gap: 30px;
}

.icon_container {
    display: flex;
    margin-bottom: 16px;
    font-weight: bold;
}
