@import "../../App.scss";
@import "../../easyui-components/easyui.scss";

.commands {
    display: flex;
    gap: 20px;
}

.featured_page {
    @include easy-ui-table;
    @include easy-ui-pagination;
    @include home-nested-page;
}
