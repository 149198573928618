@import "../easyui.scss";

.custom_select {
    &.white_bg {
        :global .ant-select:not(.ant-select-open) .ant-select-selector {
            background-color: white !important;
        }

        :global .ant-select {
            border: 2px solid white !important;
        }
    }

    &:not(.white_bg) {
        :global .ant-select:not(.ant-select-open) .ant-select-selector {
            background-color: $light-grey !important;
        }

        :global .ant-select {
            border: 2px solid $light-grey !important;
        }
    }

    :global .ant-select:not(.ant-select-open) .ant-select-selector {
        border: none !important;
        box-shadow: none !important;
    }

    :global .ant-select .ant-select-selector {
        border: none !important;
        box-shadow: none !important;
        background-color: white !important;
        min-height: 32px;
    }

    :global .ant-select.ant-select-open {
        border: 2px solid black !important;
    }

    :global .ant-select-selection-item {
        display: block;
        overflow: hidden;
        align-items: center;
        padding-top: 1px !important;
    }

    :global {
        .ant-select-multiple .ant-select-selection-item {
            background-color: white;
            border: 2px solid black;
            color: black;
            display: flex;
            padding-top: 1px !important;

            svg {
                fill: black;
            }
        }
    }
}

.options {
    :global
        .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        background-color: $light-red !important;
    }

    :global
        .ant-select-item-option-selected:not(.ant-select-item-option-disabled)
        .ant-select-item-option-state {
        color: black;
    }
}
