@import "../../App.scss";
@import "../../easyui-components/easyui.scss";

.query_box {
    margin-bottom: 30px;
    width: 300px;
}

.pagination {
    margin-top: 30px;
}

.deals_bucket_page {
    @include easy-ui-pagination;
    @include easy-ui-table;
    @include home-nested-page;
}
