@import "../../App.scss";

$side-padding: 16px;
$form-width: 452px;

:global textarea.ant-input {
    height: 30vh !important;
}

:global .ant-btn-primary {
    border-radius: 0 !important;
    border: 0;
}

.shadow_switch_holder {
    position: absolute;
    height: 100%;
    left: $side-menu-width;
    width: calc(100% - #{$form-width} - #{$side-menu-width});
    display: flex;
    justify-content: center;
    align-items: center;
}

.preview_holder {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $light-grey;
    position: relative;
}

.images_holder {
    gap: 20px;
    padding: 20px 0 0 0;
    display: flex;
}

.deal_page {
    height: 100%;
    display: grid;
    grid-template-columns: 1fr $form-width;
    grid-template-rows: 1fr;
}

.side_form {
    padding: $side-padding 0;
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.spinner_center {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
}

.basic_input {
    padding: 0 $side-padding;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;

    & > span:first-of-type {
        font-weight: bold;
    }
}

.separator {
    width: 100%;
    height: 1px;
    background-color: $light-border-color;
    margin: 4px 0;
    flex-shrink: 0;
}

.tos {
    padding: 0 $side-padding;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
}

.select_with_preview {
    display: flex;
    align-items: center;

    & > span:first-of-type {
        font-weight: bold;
    }

    svg {
        position: relative;
        top: 2px;
    }
}

.display_mode_holder {
    position: absolute;
    top: 12px;
    right: 12px;
}

.image_and_thumbnail {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 $side-padding;

    .image,
    .thumbnail > span:first-of-type {
        font-weight: bold;
    }

    .image,
    .thumbnail {
        width: 192px;
    }
}

.description {
    & > span:first-of-type {
        font-weight: bold;
    }
    padding: 0 $side-padding;
}

.reissue_button {
    width: calc(100% - 32px);
    display: flex;
    justify-content: center;
    margin: 0 16px;
}

.rectangle_image {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    width: 100%;
    padding: 0 $side-padding;
    overflow: hidden;

    span:first-of-type {
        font-weight: bold;
    }
}
