@import "../../App.scss";

.page {
    display: grid;
    grid-template-columns: 300px 1fr auto;

    h1 {
        font-size: 40px;
    }
}

.agvt {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 20px;
}

.actual_content {
    height: 100vh;
    overflow-y: scroll;
    padding: 10px;
    scroll-behavior: smooth;
}

@each $name, $color in $material-colors {
    .material_#{$name}_text {
        color: $color;
    }
}

.interactive_h2 {
    cursor: pointer;
    margin-top: 30px;
    margin-bottom: 0;

    svg {
        position: relative;
        top: 3px;
        left: 5px;
    }

    &.is_glowing {
        color: map-get($material-colors, "blue");
        svg {
            fill: map-get($material-colors, "blue");
        }
    }
}

.interactive_link {
    color: map-get($material-colors, "blue");
    text-decoration: underline;
    cursor: pointer;
}

.side_nav {
    padding: 20px;

    h1 {
        font-size: 30px;
        cursor: pointer;
    }

    h6 {
        font-size: 18px;
        cursor: pointer;
    }
}

.store_group_image {
    width: 100%;
    max-width: 700px;
}
