@import "../../App.scss";

.container {
    @include home-nested-page;
}

.editor_holder {
    border: 2px solid black;
    padding: 10px 10px 10px 0;
    margin-top: 20px;
}

.top_content {
    display: flex;
    justify-content: space-between;
}
