@import "./easyui-components/easyui.scss";
@import "./easyui-components/button/style.module.scss";

$small-screen-breakpoint: 1224px;
$tablet-breakpoint: 992px;
$mobile-breakpoint: 500px;

$ice-blue: #e8f0f6;
$bg-blue: rgb(240, 245, 249);
$ct-red: #ee1313;
$ct-blue: #1366a6;
$greyish-brown: rgb(74, 74, 74);
$light-black: #333;
$light-border-color: #f0f0f0;
$smooth-grey: #f2f1f2;

$grey-500: #667085;
$grey-800: #1d2939;

$material-colors: (
    "blue": rgb(25, 117, 210),
    "red": #b71c1c,
    "purple": #6a1b9a,
    "green": #2e7d32,
    "orange": #f57c00,
);

$home-side-padding: 16px;
$side-menu-width: 250px;

@mixin home-nested-page {
    padding: 16px 16px 0;
}

:global .ant-pagination {
    text-align: right;
    margin: 16px 0;
}
:global .ant-avatar {
    border-radius: 0 !important;
}

:global .ant-spin-dot-item {
    background-color: $ck-red;
}

:global .ant-spin-spinning {
    display: flex;
    align-items: center;
}

h1 {
    font-family: Gotham-Bold;
    font-size: 1.5em;
}
h2 {
    font-family: Gotham-Bold;
    font-size: 1.3em;
}
h3 {
    text-transform: uppercase;
    font-family: Gotham-Bold;
    color: #4a4a4a;
}
ul {
    padding-left: 20px;
}
:global .ant-card-meta-title {
    text-transform: uppercase;
    font-family: Gotham-Bold;
    color: #4a4a4a;
    margin-bottom: 20px;
}
:global .ant-card-meta-avatar {
    margin-top: -8px;
}
:global .left_label {
    display: inline-block;
    margin-right: 12px;
}
:global .text_input {
    width: 260px;
    margin-right: 40px;
}
:global .dates_holder {
    white-space: normal;
    font-size: 12px;

    & > div:not(.mid_label) {
        /* margin: 0 5px; */
        padding: 0 7px;
        border-right: 1px solid #f0f0f0;
        margin-bottom: 15px;
    }
    & > div:last-child {
        border: none;
    }
}
:global .left_spacer {
    margin-left: 20px;
}
:global .lil_top_spacer {
    margin-top: 6px;
}
:global .top_spacer {
    margin-top: 20px;
}
:global .lil_top_spacer {
    margin-top: 8px;
}
:global .clickable_table tr {
    cursor: pointer;
}

:global .simple-tag {
    color: rgba(0, 0, 0, 0.85);
    display: inline-block;
    height: auto;
    margin-right: 8px;
    padding: 0 7px;
    font-size: 12px;
    line-height: 20px;
    border: 2px solid black;
    background-color: white;
}

$thumbnail-diameter: 140px;
:global {
    .carousel_wrapper {
        width: $thumbnail-diameter;
        background: green;
        overflow: hidden;
        .ant-carousel {
            width: $thumbnail-diameter;
        }
        .ant-carousel .slick-slider {
            text-align: center;
            height: $thumbnail-diameter;
            width: $thumbnail-diameter;
            line-height: $thumbnail-diameter;
            overflow: hidden;
        }
    }
}
:global .thumbnail {
    width: $thumbnail-diameter;
    height: $thumbnail-diameter;
}
:global .acid_blue {
    color: #1890ff;
}
:global .light_label {
    font-family: Gotham-Book;
    color: $gray-dark;
}
:global .big_label {
    font-size: 20px;
}
:global .mid_label {
    font-size: 15px;
}
:global .lil_label {
    font-size: 10px;
}

:global .buttons_holder {
    margin: 24px 0 12px 0;

    #create-button.ant-btn-primary {
        background-color: #4caf50;
        border-color: #4caf50;

        &[disabled] {
            opacity: 0.6;
        }
    }

    & > button {
        margin-right: 20px;
    }
}

:global .title-and-button {
    display: flex;
    width: 100%;
    gap: 80px;
    align-items: flex-start;
    justify-content: space-between;
}

:global .ant-modal-title {
    font-family: Gotham-Ultra;
}

:global .ant-modal .ant-btn {
    @include secondary-button;
}

:global .ant-modal .ant-btn-primary {
    @include button;
}

:global .toggle-container {
    display: flex;
}

@font-face {
    src: url("./fonts/gotham_black.ttf");
    font-family: Gotham-Black;
}

@font-face {
    src: url("./fonts/gotham_bold.ttf");
    font-family: Gotham-Bold;
}

@font-face {
    src: url("./fonts/gotham_book.ttf");
    font-family: Gotham-Book;
}

@font-face {
    src: url("./fonts/gotham_book_italic.ttf");
    font-family: Gotham-Book-Italic;
}

@font-face {
    src: url("./fonts/gotham_medium.ttf");
    font-family: Gotham-Medium;
}

@font-face {
    src: url("./fonts/gotham_ultra.woff");
    font-family: Gotham-Ultra;
}

@font-face {
    src: url("./fonts/LibreBarcode128-Regular.ttf");
    font-family: BarCode;
}

:global .nav_header {
    height: 44px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    border-bottom: 1px solid $light-border-color;
    position: relative;
}

:global .nav_button_holder {
    display: flex;
    align-items: center;
    gap: 8px;
}

:global .ant-modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
:global .ant-modal-confirm-btns {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.sim_highlight {
    font-weight: bold;
    color: $ck-orange;
}

.required:after {
    margin-left: 2px;
    font-size: 16px;
    content: " ∗";
    color: $ct-red;
}
