@import "../../App.scss";
@import "../../easyui-components/easyui.scss";

.card {
    width: 310px;
    height: 140px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 10px;
    border: 1px solid transparentize($light-border-color, 0.1);
    background-color: white;
    position: relative;

    &.fluid {
        width: auto;
    }

    &.coupon {
        border-style: dashed;
    }

    &.age_restricted .card_body .description {
        max-width: none;
        white-space: pre-wrap;
    }

    .card_body {
        flex-direction: column;
        display: flex;
        font-family: gotham-book;
        gap: 5px;
        min-width: 0;
        flex-grow: 1;

        .category {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            color: $font-light-grey;
            font-size: 12px;
        }

        .title {
            font-size: 18px;
            font-family: Gotham-Bold;
            line-height: 16px;
            line-height: 22px;
            white-space: pre-wrap;
        }

        .description {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            max-width: calc(100% - 3rem);

            color: $font-dark-grey;
        }

        .card_expiration {
            display: inline-block;
            font-size: 11px;
            font-family: Gotham-Medium;
            line-height: 2;
            letter-spacing: 0.05rem;
            color: $font-medium-grey;

            &.expired {
                color: $ct-red;
            }
        }
    }
}

.coupon_button {
    width: 24px;
    height: 24px;
    border: 2px solid black;
    border-radius: 50%;
    position: absolute;
    bottom: 12px;
    right: 12px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.25s;

    svg {
        transition: all 0.25s;
    }

    &.coupon_selected {
        background-color: black;
        transform: rotate(45deg);

        svg {
            fill: white;
            transform: scale(1.05);
        }
    }
}

.lock_svg {
    margin: 0 12px 0 16px;
}

.title_highlight {
    color: $orange-light;
}
