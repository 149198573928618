.image_holder {
    overflow: hidden;
    flex-shrink: 0;
}

.image {
    user-select: none;
    width: auto;
    height: auto;
    max-width: 100%;
    height: 100%;

    &.expired {
        filter: grayscale(100%);
    }
}
