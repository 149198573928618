@mixin easy-ui-table {
    :global .ant-table-thead > tr > th {
        background-color: white;
        border-color: black;
        color: black;
        border-width: 2px;
    }

    :global
        .ant-table-thead
        > tr
        > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
        background-color: black;
        width: 2px;
    }

    :global .ant-table-tbody > tr > td {
        border-bottom: 1px solid rgba(206, 201, 201, 0.2);
        transition: background 0.3s;
    }
}
