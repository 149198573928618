@import "../../easyui-components/language-picker/style.module.scss";

.lang_selector {
    @include lang-selector;
}

.options {
    :global
        .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        background-color: $light-red !important;
    }

    svg {
        color: $ck-red;
    }
}
