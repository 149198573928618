@import "../../App.scss";
@import "../../easyui-components/easyui.scss";

:global form.small_form > div {
    margin-bottom: 20px;
    max-width: 400px;
}

.table {
    min-width: 100%;
}

.date_status {
    color: $greyish-brown;
    text-transform: capitalize;
}

.title {
    font-family: Gotham-Book;
    font-size: 13px;
    min-width: 200px;
}

.dates_holder {
    white-space: nowrap;
    font-size: 12px;

    & > div {
        /* margin: 0 5px; */
        padding: 0 7px;
        border-right: 1px solid #f0f0f0;
    }
    & > div:last-child {
        border: none;
    }
}

.spacer {
    height: 30px;
}

.category_page {
    @include easy-ui-table;
    @include easy-ui-pagination;
}