@import "../../easyui-components/easyui.scss";

$ratio: 185 / 300;
$side-padding: 12px;

.content_holder {
    --height: 70vh;
    --width: calc(var(--height) * #{$ratio});

    box-sizing: content-box !important;
    position: relative;
    overflow: hidden;
    border-bottom: 40px solid black;
    border-top: 40px solid black;
    border-left: 10px solid black;
    border-right: 10px solid black;
    border-radius: 10px;

    resize: horizontal;
    max-width: 550px;
    min-width: 350px;

    // Hardcode value to match the phone background
    height: var(--height);
    width: var(--width);

    max-height: 600px;

    background-color: white;
}

.content {
    height: 100%;
    overflow-y: scroll;
    padding-bottom: 50px;
    width: 100%;
    height: 100%;

    &::-webkit-scrollbar {
        display: none;
    }
}

.navigation {
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: white;
    box-shadow: 0 -3px 4px rgba(0, 0, 0, 0.1);
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

/**
    SHARED COMPONENT
**/

.deal_list {
    display: flex;
    gap: $side-padding;
    overflow-x: auto;
    padding: 10px 12px;
}

.list_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $side-padding 0;
    padding-bottom: calc(#{$side-padding} - 10px);
    margin: 16px $side-padding 0;

    span:nth-child(1) {
        font-family: Gotham-Medium;
        font-size: 16px;
        text-transform: uppercase;
    }

    span:nth-child(2) {
        font-family: Gotham-Medium;
        font-size: 14px;
        cursor: pointer;
    }
}

.sticky_header {
    height: 64px;
    width: 100%;
    position: sticky;
    top: 0;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: $side-padding;
    text-transform: uppercase;
    cursor: pointer;
    z-index: 1;

    span {
        font-family: Gotham-Medium;
        font-size: 18px;
    }
}

.back_button {
    font-size: 14px;
    display: inline-block;
    text-transform: uppercase;
    cursor: pointer;
}

.list_holder {
    position: relative;
}

.list_glower {
    position: absolute;
    top: 0;
    left: 10px;
    width: calc(100% - 20px);
    height: 100%;
    pointer-events: none;
}
