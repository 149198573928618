@import "../easyui.scss";

$transition-time: 0.2s;
$border-width: 1px;

@mixin button {
    color: white;
    padding: 4px 26px;
    background-color: $ck-red;
    border: $border-width solid $ck-red;
    display: inline-block;
    outline: none;
    cursor: pointer;
    transition: all $transition-time;
    display: inline-flex;
    gap: 6px;
    align-items: center;
    font-family: Gotham-Medium;

    svg {
        fill: white;
        transition: all $transition-time;
    }

    &:hover {
        background-color: $ck-dark-red;
        border: $border-width solid $ck-dark-red;
    }

    &:active {
        background-color: $ck-dark-red;
        border: $border-width solid $ck-dark-red;
        box-shadow: 0 0 0 0.2rem rgba(224, 73, 62, 0.5);
    }

    &:disabled {
        background-color: $light-grey;
        border: $border-width solid transparent;
        cursor: not-allowed;
        color: $gray60;
    }
}

@mixin secondary-button {
    padding: 4px 26px;
    outline: none;
    cursor: pointer;
    transition: all $transition-time;
    display: inline-flex;
    gap: 6px;
    align-items: center;
    background-color: white;
    border: $border-width solid black;
    color: black;

    svg {
        fill: black;
        transition: all $transition-time;
    }

    &:hover {
        background-color: black;
        border: $border-width solid black;
        color: white;
        svg {
            fill: white;
        }
    }

    &:active {
        box-shadow: 0 0 0 0.2rem rgba(127, 127, 127, 0.5);
        background-color: #7f7f7f;
        border-color: #7f7f7f;
        color: white;
        svg {
            fill: white;
        }
    }

    &:disabled {
        background-color: $light-grey;
        border: $border-width solid transparent;
        cursor: not-allowed;
        color: $gray60;
    }
}

.button {
    @include button;
    &.secondary {
        @include secondary-button;
    }
    &.tertiary {
        background-color: $ck-orange;
        border: $border-width solid $ck-orange;
        &:hover {
            background-color: darken($ck-orange, 5);
            border: $border-width solid darken($ck-orange, 5);
        }
        &:disabled {
            background-color: $light-grey;
            border: $border-width solid transparent;
        }
    }
}
