@import "../../App.scss";
@import "../../easyui-components/easyui.scss";

.buttons_holder {
    margin-bottom: 16px;

    button {
        margin-right: 20px;
        display: inline-block;
    }
}

.hidden_input {
    visibility: hidden;
    width: 0;
    height: 0;
}

.preview_image {
    max-width: 472px;
}

:global .public-DraftStyleDefault-block.public-DraftStyleDefault-ltr {
    padding: 8px 20px;
}

.type_svg svg {
    width: 24px;
    height: 24px;
    position: relative;
    top: 4px;
}

.add_rich_text_input {
    width: 400px;
}

.rich_text_modal {
    font-family: Gotham-Bold;
    input {
        margin-left: 8px;
        margin-bottom: 12px;
    }
}

.commands {
    display: flex;
    gap: 20px;
}

.static_content_page {
    @include easy-ui-pagination;
    @include easy-ui-table;
    @include home-nested-page;
}
