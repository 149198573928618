@import "../../../easyui-components/easyui.scss";

.header {
    width: 100%;
    height: 158px;
    background-color: $ck-red;
    padding: 15px;
    position: relative;
}

.welcome_message {
    font-family: Gotham-Black;
    color: white;
    font-size: 32px;
    margin-top: 6px;
    margin-bottom: 13px;

    span {
        font-size: 16px;
        position: relative;
        bottom: 15px;
        left: 5px;
    }
}

.easy_message {
    font-family: Gotham-Bold;
    color: white;
    font-size: 14px;
}

.bell_svg {
    fill: white;
    position: absolute;
    top: 20px;
    right: 20px;
}
