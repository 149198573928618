@import "../../App.scss";

.page {
    display: flex;
    flex-direction: column;
    @include home-nested-page;
}

.commands {
    display: flex;
    gap: 40px;
    align-items: center;

    h1 {
        margin-bottom: 0;
    }
}
