@import "./pagination/style.module.scss";
@import "./table/style.module.scss";

$ck-blue: #004b87;
$indigo: #6610f2;
$ck-orange: #ed8b00;
$ck-red: #da291c;
$ck-dark-red: #ad2116;
$ck-yellow: #eaaa00;
$green: #28a745;
$cyan: #17a2b8;
$white: #fff;
$gray60: #7f7f7f;
$gray40: #b2b2b2;
$gray-dark: #4c4c4c;
$primary: #da291c;
$secondary: #7f7f7f;
$tertiary: #ed8b00;
$success: #28a745;
$info: #004b87;
$warning: #eaaa00;
$danger: #c33;
$light: #f0f0f0;
$dark: #4c4c4c;
$light-grey: #f5f5f5;
$light-red: #da291c3d;
$snackbar-success: #245f17;
$snackbar-warning: #6b4e00;
$snackbar-error: #951c13;
$light-border-color: #e5e5e5;
$orange-light: #f19415;

// Font color
$font-light-grey: #979797;
$font-dark-grey: #3a3a3a;
$font-medium-grey: #777777;

@mixin header-text($size) {
    color: $dark;
    font-family: Gotham-Ultra;
    font-size: $size;
    font-weight: 900;
}

:global .h1-header {
    @include header-text(40px);
}
:global .h2-header {
    @include header-text(36px);
}
:global .h3-header {
    @include header-text(32px);
}
:global .h4-header {
    @include header-text(28px);
}
:global .h5-header {
    @include header-text(24px);
}
:global .h6-header {
    @include header-text(20px);
}
