@import "../../../easyui-components/easyui.scss";

.store_picker {
    padding: 24px 15px;
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 8px;
    border-bottom: 1px solid $light-border-color;
    margin-bottom: 20px;

    p {
        margin: 0;
    }
}

.title {
    font-family: Gotham-Medium;
    font-size: 16px;
}

.sub_title {
    font-family: Gotham-Book;
    font-size: 14px;
    color: $font-dark-grey;
}

.current_store {
    font-family: Gotham-Book;
    font-size: 14px;
    color: $font-dark-grey;
}
