@import "../../App.scss";
@import "../promotion-page/style.module.scss";

.container {
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 452px;
    grid-template-rows: 1fr;
}

.custom_option {
    display: flex;
    align-items: center;
}

.custom_margin {
    margin: 0 50px 0 130px;
}

.titles_holder {
    display: flex;
    flex-direction: column;
    text-overflow: ellipsis;
    flex-grow: 1;
    width: 100px;
    box-sizing: border-box;
    padding-right: 6px;

    & > span {
        text-overflow: ellipsis !important;
        display: block;
        box-sizing: border-box;
        overflow: hidden;
    }
}

.option_image {
    flex-shrink: 0;
}

.image_holder_bg {
    img {
        background: rgba(0, 0, 0, 0.1);
    }
}

.fixed_width {
    width: 50%;
}

.item_padding {
    display: flex;
    flex-direction: column;

    :global .ant-input {
        margin: 4px 0;
    }
}

.toggle_container {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}
