@import "../../App.scss";
@import "../../easyui-components/easyui.scss";

.card {
    width: calc(100% - 24px);
    height: 140px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    padding: 10px;
    border: 1px solid transparentize($light-border-color, 0.1);
    border-left: 8px solid $orange-light;
    background-color: white;
    position: relative;
    margin: 12px 0 0 12px;

    .title {
        font-size: 22px;
        font-family: Gotham-Black;
    }

    .description {
        font-family: gotham-book;
        line-height: 100%;
        margin-top: 8px;
    }

    .left {
        @extend .flex-col-bet;
        width: 175px;
    }
    .right {
        @extend .flex-col-bet;
        align-items: center;
    }

    .fake_link_container {
        display: flex;
        align-items: center;

        .fake_link {
            font-size: 12px;
            margin-right: 4px;
            text-decoration: underline;
        }
    }
}

.external_url {
    width: calc(100% - 24px);
    margin: 12px 0 0 12px;

    img {
        width: 100%;
        object-fit: cover;
    }
}

.flex-col-bet {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}
