@import "../../App.scss";
@import "../../easyui-components/easyui.scss";

.rich_text_editor {
    background-color: $light-grey;
    border: 2px solid $light-grey;
    position: relative;
    line-height: 21px;
    display: grid;
    grid-template-rows: max-content 1fr;
    grid-template-columns: 1fr;
    height: 30vh !important;
    overflow: scroll;

    &:focus-within {
        background-color: white;
        border-color: black;

        .toolbar {
            background-color: white;
        }
    }

    :global {
        .DraftEditor-editorContainer {
            height: 100%;
        }

        .public-DraftEditor-content {
            height: 100%;
        }
    }
}

.is_disabled {
    opacity: 0.3;
}

:global .inline-link {
    color: $ct-blue;
    text-decoration: underline;
}

.rich_text_editor .editor-container {
    overflow: scroll;
    padding: 20px;
    flex: 1;
    max-width: 100%;
    height: 100%;
}

.rich_text_editor .toolbar {
    background-color: $light-grey;
    vertical-align: middle;
    display: flex;
    flex-direction: row;
    padding: 12px 12px;
    gap: 6px;
    position: sticky;
    top: 0;
}

.rich_text_editor .toolbar :global .ant-btn {
    color: black;
    border: 2px solid black;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;

    &:focus {
        border-color: $ck-orange;

        svg {
            fill: $ck-orange;
        }
    }
}

.remove_icon {
    svg {
        transition: fill 0.3s;
        margin-left: 6px;
    }

    &:hover svg {
        fill: tomato;
        cursor: pointer;
    }
}
