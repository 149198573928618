@import "../../App.scss";

.link {
    text-decoration: none;
    border: none;
    background-color: $white;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }

    &:active {
        text-decoration: underline;
    }
}