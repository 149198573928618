@import "../../App.scss";

@each $name, $color in $material-colors {
    @keyframes #{$name}-pulse {
        0% {
            box-shadow: 0 0 0 0 rgba($color, 0.7);
        }

        70% {
            box-shadow: 0 0 0 10px rgba($color, 0);
        }

        100% {
            box-shadow: 0 0 0 0 rgba($color, 0);
        }
    }
}
