@import "../../easyui-components/easyui.scss";

@mixin helpful_text($top, $left) {
    span {
        display: inline-block;
        opacity: 0;
        transform: translateX(-5px);
        position: relative;
        top: $top;
        left: $left;
        transition: all 0.25s;
        cursor: pointer;
        color: $ck-orange;
    }

    &:hover span {
        opacity: 1;
        transform: translateX(0);
    }
}

.helpful_span {
    @include helpful_text(0, 4px);

    span {
        font-size: 16px;
    }
}

.helpful_h1 {
    @include helpful_text(1px, 9px);
}
