@import "../../easyui-components/easyui.scss";
@import "../../App.scss";

.query_box {
    display: flex;
    gap: 20px;
    align-items: flex-start;
}

.pagination_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.weekday_label {
    width: 100px;
    display: inline-block;
}

.stores_page {
    @include easy-ui-table;
    @include easy-ui-pagination;
    @include home-nested-page;
}

.sim_service_list {
    margin-bottom: 0;
}
