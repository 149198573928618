@import "../../easyui-components/easyui.scss";
@import "../../App.scss";

.container {
    @include home-nested-page;
}

.benefit_columns {
    width: 100%;
    display: flex;
    gap: 50px;
}

.single_column {
    width: 100%;
}
// CREATE MODAL
.create_form {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}
.left_inputs {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.input_container {
    display: flex;
    flex-direction: column;
}

// GENERAL LIST
.type_header {
    display: flex;
    gap: 20px;
    align-items: center;
    margin-bottom: 20px;

    h2 {
        margin: 0;
    }
}

// BENEFIT ROW
.benefit_row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $light-grey;
    padding: 5px 15px;
}
.benefit_command {
    justify-content: space-between;
    display: flex;
}
.benefit_icon {
    width: 40px;
    height: 40px;
    margin-right: 10px;
}
.benefit_left {
    @extend .benefit_command;
    align-items: center;
}
