@import "../../App.scss";

.shadow_switch {
    background-color: $smooth-grey;
    border-radius: 6px;
    padding: 4px;
    height: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: Gotham-Book;
    font-weight: 500;

    span {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        height: 24px;
        padding: 3px 14px;
        cursor: pointer;
        transition: background-color 0.25s linear, box-shadow 0.25s linear;
        color: $grey-500;
    }

    span.selected {
        background: white;
        box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
            0px 1px 2px rgba(16, 24, 40, 0.06);
        border-radius: 4px;
        color: $grey-800;
    }
}
