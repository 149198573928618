@import "../../App.scss";
@import "../../easyui-components/easyui.scss";

.commands {
    display: flex;
    gap: 20px;
}

.loyalty_page {
    @include easy-ui-table;
    @include easy-ui-pagination;
    @include home-nested-page;
}

.image {
    object-fit: cover;
    width: $thumbnail-diameter;
    height: $thumbnail-diameter;
}
