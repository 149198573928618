@import "../../easyui-components/easyui.scss";
@import "../../App.scss";

.nref_add {
    font-weight: bold;

    &:hover {
        color: #2e7d32;
        cursor: pointer;
    }
}

.nref_remove {
    font-weight: bold;

    &:hover {
        color: #c62828;
        cursor: pointer;
    }
}

.modal_top_content {
    display: grid;
    grid-template-columns: 1fr 1fr;

    .delete_button_cell {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 20px;
    }

    :global .ant-spin-dot-item {
        background-color: $ck-orange;
    }
}

.store_group_modal {
    span {
        display: inline-block;
        margin: 2px 0;
    }

    font-family: Gotham-Medium;

    :global .ant-checkbox-checked .ant-checkbox-inner {
        background-color: black;
        border-color: black;
    }

    :global .ant-checkbox-checked::after {
        border: none;
    }

    :global .ant-checkbox-input:focus + .ant-checkbox-inner,
    :global .ant-checkbox:hover .ant-checkbox-inner {
        border-color: initial;
    }

    :global .ant-transfer-operation button {
        display: flex;
        justify-content: center;
    }

    // Search box
    :global .ant-transfer-list-search {
        display: flex;
        background-color: $light-grey;
        border: 2px solid $light-grey;
        transition: none;

        &:focus-within {
            border: 2px solid black !important;
            background-color: white !important;
            box-shadow: none !important;
        }

        :global .ant-input {
            background-color: $light-grey !important;
            transition: none;
            margin-left: 4px;

            &:focus {
                background-color: white !important;
            }
        }
    }

    :global
        .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
        border-color: black;
        border-right-width: 2px;
        z-index: 1;
    }

    // Checked stores
    :global .ant-transfer-list-content-item-checked {
        background-color: $light-red !important;
    }

    // Page Indicator
    :global .ant-pagination-simple .ant-pagination-simple-pager input {
        background-color: $light-grey;
        border: none;
        transition: none;

        &:focus {
            border: 2px solid black !important;
            background-color: white !important;
        }
    }
}

.store_group_name {
    width: 400px;
    margin-bottom: 20px;
}

.store_group_description {
    width: 400px;
    margin-bottom: 20px;
}

.included_stores {
    width: 50%;
}

.all_stores {
    width: 50%;
}

.all_stores_list {
    height: 500px;
    overflow-y: scroll;
}

.all_stores_header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 20px;

    h2 {
        margin-bottom: 0;
        margin-right: 20px;
        font-size: 24px;
    }

    input {
        width: 300px;
    }
}

.store_group_page {
    @include easy-ui-table;
    @include easy-ui-pagination;
    @include home-nested-page;
}

.auto_generated {
    font-size: 0.7rem;
    margin-left: 0.5rem;
    padding: 2px 4px;
    border-radius: 4px;
    background-color: lighten($success, 50);
}

.auto_generated_container {
    display: flex;
    justify-content: space-between;
}
