@import "../../App.scss";
@import "../../easyui-components/easyui.scss";

.container {
    padding: 30px;
    width: 100%;
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    input {
        width: 322px;
    }

    form {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
}

.submit_button {
    margin: 15px 15px 0 0;
}

.login-page {
    @include easy-ui-table;
    @include easy-ui-pagination;
}
