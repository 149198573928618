@import "../style.module.scss";

.white_row {
    height: 64px;
    width: 100%;
    background-color: white;
    display: flex;
    align-items: center;
    padding: 0 15px;
    gap: 7px;
    z-index: 1;

    &.sticky {
        position: sticky;
        top: 0;
    }

    &.bordered {
        border-bottom: 1px solid $light-border-color;
    }

    .left_content {
        flex-grow: 1;
        display: flex;
        gap: 7px;
        align-items: center;
    }
}

.orange_row {
    height: 48px;
    width: 100%;
    background-color: $orange-light;
    display: flex;
    align-items: center;
    padding: 0 15px;
    gap: 7px;
}

.inner_circle_header {
    flex-grow: 1;
    font-family: Gotham-Medium;
    font-size: 16px;
    position: relative;
    top: 1px;
}

.store_name {
    flex-grow: 1;
    font-family: "Gotham-Book";
    position: relative;
    top: 1px;
}

.change_cta {
    font-family: Gotham-Medium;
    text-decoration: underline;
}
