@import "../../easyui-components/easyui.scss";
@import "../../App.scss";

.page {
    overflow: scroll;
    border: 1px solid crimson;
    height: 100vh;
    @include home-nested-page;
}

.grid {
    display: grid;
}

.grid_header {
    display: grid;
    position: sticky;
    top: -16px;

    & > div {
        font-weight: 600;
        display: flex;
        border-bottom: 2px solid black;
        justify-content: center;
        background-color: white;
        padding: 8px 0;
    }
}

.cell {
    display: flex;
    justify-content: center;
    align-items: center;
    //border-right: 1px solid lightgray;
    padding: 5px 0;

    &.odd {
        background-color: #d3d3d359;
    }
}

.top_content {
    position: sticky;
    left: 16px;
    margin: 16px;
}

.store_id {
    color: $ck-orange;
}

.select_boxes {
    display: flex;
    gap: 30px;
}

.select_box {
    display: flex;
    flex-direction: column;
    margin: 16px 0 16px;
}

.hidden_input {
    visibility: invisible;
    width: 0;
    height: 0;
}

.buttons_holder {
    display: flex;
    margin-top: 20px;
    gap: 20px;
}
