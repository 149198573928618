@import "../easyui.scss";

.custom_input {
    color: #000;
    background-color: $light-grey;
    font-size: 0.875rem;
    font-weight: 400;
    text-indent: 0.5rem;
    min-width: 4.25rem;
    border: 2px solid $light-grey;
    outline: none;
    padding: 5px 4px;

    &:focus {
        border: 2px solid black;
        background-color: white;
    }

    &::placeholder {
        color: rgb(191, 191, 191);
    }

    &.disabled {
        cursor: not-allowed;
        opacity: 0.3;
    }
}

.input_wrapper {
    position: relative;
    display: inline-block;

    svg {
        opacity: 0;
        transition: opacity 0.3s;
        cursor: pointer;
        position: absolute;
        right: 12px;
        top: 50%;
        transform: translateY(-50%);
    }

    &:hover svg {
        opacity: 1;
    }
}
