@import "../style.module.scss";

.activation_zone {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $light-grey;
    border: 1px solid $light-border-color;
    border-radius: 5px;
    padding: 20px 20px 6px;
}

.title {
    font-family: Gotham-Bold;
    font-size: 14px;
}

.normal_text {
    font-family: Gotham-Book;
    font-size: 14px;
}

.countdown {
    font-family: Gotham-Medium;
    font-size: 48px;
    color: $ck-red;
    margin-bottom: 10px;
}

.bar_code {
    font-family: BarCode;
    position: absolute;
    font-size: 50px;
    transform: scaleY(1.7) translateY(3px);
}

.bar_code_holder {
    height: 58px;
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
}

.seconds_holder {
    width: 85px;
    display: inline-block;
}
