.ck_logo {
    font-family: Gotham-Ultra;
    display: flex;
    align-items: center;

    img {
        width: 20px;
        height: 20px;
        margin-right: 8px;
    }

    .back_button {
        margin-right: 14px;
        cursor: pointer;
    }

    .extra_content_caret {
        margin: 0 8px;
    }
}
