@import "../../easyui-components/easyui.scss";
@import "../../App.scss";
@import "../promotion-page/style.module.scss";

.container {
    @include home-nested-page;
}

.content {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    row-gap: 20px;
    column-gap: 40px;

    & > div {
        display: flex;
        flex-direction: column;
    }
}

.in_app {
    color: $ck-orange;
}

.external_url {
    color: $ck-blue;
}
