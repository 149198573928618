@import "../../App.scss";
@import "../promotion-page/style.module.scss";

:global textarea.ant-input {
    height: 30vh !important;
}

:global .ant-btn-primary {
    border-radius: 0 !important;
    border: 0;
}

.container {
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 452px;
    grid-template-rows: 1fr;
}

h1 {
    font-family: Gotham-Bold;
    font-size: 1.5em;
}
h2 {
    font-family: Gotham-Bold;
}

.age_range_label {
    margin-right: 12px;
}

.input_row_centered {
    @extend .input_row;
    justify-content: center;
}

.fluid_input_col {
    display: flex;
    flex-direction: column;
    margin-right: 16px;
}

.vertical_col {
    display: flex;
    flex-direction: column;
}

.coupon_type_info {
    display: flex;
}

.divider {
    &::before,
    &::after {
        border-top: 1px solid rgba(0, 0, 0, 0.2) !important;
    }
}

.section_one {
    width: 100%;
    display: flex;
}

.left_column {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.right_column {
    display: flex;
    justify-content: flex-end;
}

.config_container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
}

.items_defs_box {
    width: min-content;
    background-color: #ffffff;
    border: 1px solid #f0f0f0;
    border-radius: 40px;
    box-shadow: 0 -15px 15px -15px rgba(0, 0, 0, 0.2);
    display: flex;
    padding: 20px;
    margin-top: 20px;

    .fixed_width_input_col:not(:last-child) {
        margin-right: 40px;
    }
    .fixed_width_input_col:last-child {
        margin-right: 0;
    }
}

.category_field {
    margin-top: 10px;
    width: 100%;
    align-items: center;
}

.category_area {
    width: min-content;
    min-width: 100px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    background-color: rgb(216, 237, 245);
    color: rgb(3, 121, 168);
    align-items: center;
    border-radius: 25px;
}

.date_box {
    width: 300px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 -15px 15px -15px rgba(0, 0, 0, 0.2);
    margin-right: 20px;
    padding: 0 auto;
    color: white;
    background: white;
    margin: 0 2vw 0 50px;

    & > div {
        background: #1366a6;
        padding: 15px 0;
    }
}

.vertical_col_date {
    display: flex;
    flex-direction: column;
    padding-left: 20px;

    .vertical_col {
        font-size: 1.1em;
        font-family: Gotham-Bold;
        text-transform: uppercase;
        margin-top: 5px;
        margin-bottom: 5px;
    }
}

.image_field {
    margin: 0 90px;
}

.items_content {
    width: 95%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.winners_box {
    min-width: 800px;
    padding-bottom: 40px;
}

.winnable_row {
    padding: 20px 0 40px;
}

.normal {
    color: #ffffff;
    position: absolute;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    margin-top: 3px;
}

.star_img {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    position: relative;

    img {
        width: 100%;
        height: 100%;
    }
}

.winnable_buttons_row {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.switch_row {
    display: flex;
    align-items: center;
    padding: 20px 0 0 0;
    gap: 36px;

    & > div {
        display: flex;
        align-items: center;
        gap: 6px;
    }
}

.select_row {
    display: flex;
    align-items: center;
    padding: 0;
    gap: 20px;

    & > div {
        gap: 6px;
        display: flex;
        flex-direction: column;
    }
}

.top_content {
    display: flex;
    justify-content: space-between;
}

.images_holder {
    gap: 20px;
    padding: 20px 0 0 0;
    display: flex;
}

.fixed_width_input_col {
    display: flex;
    flex-direction: column;
    @media (max-width: $mobile-breakpoint) {
        width: 100%;
    }

    .select_with_preview {
        display: flex;
        align-items: center;
        gap: 10px;
    }
}

.input_row {
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media (max-width: $mobile-breakpoint) {
        flex-direction: column;
        align-items: flex-start;
    }
}

// Grid refactor
.deal_page {
    display: grid;
    grid-template-columns: 1fr 400px;
    @include home-nested-page;
}

.dates_row {
    display: grid;
    grid-template-columns: repeat(4, max-content);
    grid-template-rows: repeat(4, max-content);
    column-gap: 30px;
    row-gap: 6px;
}

.form {
    padding-bottom: 30px;
    grid-column-start: 1;
    grid-column-end: 2;
}

.header_holder {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
