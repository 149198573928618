@import "../easyui.scss";

$transition-duration: 0.2s;

.hidden_input {
    width: 0;
    height: 0;
    visibility: hidden;
}

.toggle_container {
    width: 32px;
    height: 16px;
    border: 2px solid black;
    border-radius: 2rem;
    padding: 2px;
    cursor: pointer;
    transition: all $transition-duration;

    &.checked {
        background-color: black;
    }

    &.checked .ball {
        background-color: white;
        transform: translateX(16px);
    }

    &.disabled {
        cursor: auto;
        opacity: 0.3;
    }
}

.ball {
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background-color: black;
    transition: all $transition-duration;
}
