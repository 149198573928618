@import "../../../easyui-components/easyui.scss";

.store_picker {
    margin: 0 12px;
    min-height: 120px;
    border: 1px solid $light-border-color;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: relative;

    p {
        margin: 0;
    }
}

.address {
    font-family: Gotham-Bold;
    font-size: 18px;
}

.distance {
    font-family: Gotham-Book;
    font-size: 14px;
    color: $font-dark-grey;
}

.status {
    font-family: Gotham-Medium;
    size: 14px;
    color: $font-dark-grey;

    .open {
        color: $green;
    }
}

.marker {
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: $light-grey;
    bottom: 16px;
    right: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}
