@import "../../easyui-components/easyui.scss";

.notification-error {
    background-color: $snackbar-error !important;
    color: white;

    .ant-notification-notice-message {
        color: white !important;
        margin: 0;
    }
    .ant-notification-notice-close {
        color: white;
    }
}

.notification-success {
    background-color: $snackbar-success !important;
    color: white;

    .ant-notification-notice-message {
        color: white !important;
        margin: 0;
    }
    .ant-notification-notice-close {
        color: white;
    }
}

.notification-warning {
    background-color: $snackbar-warning !important;
    color: white;

    .ant-notification-notice-message {
        color: white !important;
        margin: 0;
    }
    .ant-notification-notice-close {
        color: white;
    }
}
