@import "../../../easyui-components/easyui.scss";

.navigation_icon {
    display: inline-flex;
    flex-direction: column;
    align-items: center;

    opacity: 0.3;

    &.is_selected {
        opacity: 1;
    }

    &.is_selected .red_line {
        background-color: $ck-red;
    }

    &.clickable {
        cursor: pointer;
    }
}

.red_line {
    display: inline-block;
    width: 30px;
    height: 3px;
    border-radius: 2px;
    background-color: white;
    margin-top: 1px;
    margin-bottom: 2px;
}

.section_name {
    display: inline-block;
    margin-top: 2px;
    font-size: 10px;
    font-family: Gotham-Medium;
    text-transform: uppercase;
}
