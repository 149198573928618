@import "../style.module.scss";

.page {
    background-color: $light-grey;
    min-height: 100%;
}

.explore_subtitle {
    font-size: 12px;
    font-family: Gotham-Black;
    padding-left: $side-padding;
    display: inline-block;
    margin: 16px 0;
}

.vertical_deal_list {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 0 $side-padding 20px;
    margin-top: 30px;
}
