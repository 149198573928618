@import "../../App.scss";
@import "../../easyui-components/easyui.scss";

:global .ant-table-thead > tr > th {
    text-transform: uppercase;
    color: #313131;
    letter-spacing: 0.2em;
    font-size: 0.8em;
    font-weight: 600;
}
:global .text_center {
    text-align: center;
}
:global .vr {
    border-left: 1px solid #d6d6d6;
    min-height: 10px;
    margin: -3px 0 0 50%;
}
:global .inline_block {
    display: inline-block;
}

.date_status {
    color: $greyish-brown;
    text-transform: capitalize;
}

.thumbnail {
    object-fit: cover;
    width: $thumbnail-diameter;
    height: $thumbnail-diameter;
}

.title {
    font-family: Gotham-Medium;
    font-size: 15px;
    min-width: 200px;
}

.activations_count {
    font-family: Gotham-Book-Italic;
    font-size: 11px;
    margin-top: 10px;
}

.selects_row {
    display: flex;
    gap: 16px;
    :global .ant-select,
    :global .ant-input-affix-wrapper {
        width: 200px;
    }
    flex-wrap: wrap;
}

.promotions_page {
    @include easy-ui-table;
    @include easy-ui-pagination;
    @include home-nested-page;
}
