@import "../style.module.scss";

.coupon_button {
    position: absolute;
    bottom: 70px;
    left: 50%;
    transform: translate(-50%, 60px);
    background-color: #182b40;
    color: white;
    display: inline-block;
    padding: 10px 12px 7px;
    border-radius: 26px;
    cursor: pointer;
    font-family: Gotham-Medium;
    font-size: 12px;
    transition: transform 0.25s;
    white-space: nowrap;

    &.visible {
        transform: translate(-50%, 0);
    }
}
