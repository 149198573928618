@import "../../easyui-components/easyui.scss";

.name {
    font-size: 12px;
    color: $font-dark-grey;
    font-family: Gotham-Book;
}

.container {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding-top: 6px;
    border: 1px solid $light-border-color;

    .image_container {
        width: 125px;
        display: flex;
        justify-content: center;
    }

    .name {
        font-weight: bold;
    }
}
