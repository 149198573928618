@import "../../../easyui-components/easyui.scss";
@import "../style.module.scss";

.home_content {
    padding-bottom: 20px;
}

.deal {
    border: 1px solid $light-border-color;
    height: 140px;
    width: 309px;
    flex-shrink: 0;
}

.deal_bucket {
    border: 1px solid $light-border-color;
    height: 160px;
    width: 160px;
    flex-shrink: 0;
}

.home_page_header {
    font-family: Gotham-Bold;
    font-size: 16px;
    padding-left: $side-padding;
}

.home_page_store_picker {
    height: 48px;
    width: 100%;
    background-color: black;
    color: white;
    font-family: Gotham-Book;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px 0 6px;

    .address {
        display: flex;
        align-items: center;
        gap: 2px;
    }
    .address svg {
        transform: scale(0.85);
    }

    .change_cta {
        font-family: Gotham-Medium;
        text-decoration: underline;
    }
}

.featured_list {
    button {
        cursor: pointer;
    }
}
