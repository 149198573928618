@import "../style.module.scss";

.page_content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 24px $side-padding;
}

.deal_img {
    width: 100%;
    object-fit: cover;
}

.category {
    font-family: Gotham-Book;
    font-size: 14px;
    color: $font-dark-grey;
}

.title {
    font-family: Gotham-Black;
    font-size: 32px;
}

.subtitle {
    font-family: Gotham-Book;
    font-size: 16px;
    color: $font-dark-grey;
}

.nearest_store_label {
    font-family: Gotham-Medium;
    font-size: 16px;
}

.nearest_store_label_large {
    font-family: Gotham-Black;
    font-size: 18px;
    text-transform: uppercase;
}

.tos {
    font-family: Gotham-Medium;
    font-size: 16px;
    text-decoration: underline;
}

.description {
    font-family: Gotham-Book;
    font-size: 16px;
    color: $font-dark-grey;
}

.nearest_stores {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-bottom: 20px;
}

.bordered_header {
    border-bottom: 1px solid $light-border-color;
}

.coupon_buttons_holder {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin: 16px 0;

    button {
        flex-shrink: 1;
        flex-basis: 50%;
        justify-content: center;
        font-size: 12px;
        padding: 10px 12px 7px !important;
    }
}

.contest_button_holder {
    display: flex;
    flex-direction: row;
    margin: 16px 0;

    button {
        flex-grow: 1;
        justify-content: center;
        font-size: 12px;
        padding: 10px 12px 7px !important;
    }
}

.image_holder {
    position: relative;
}
