@mixin black-square {
    background-color: white;
    color: black;
    border: 2px solid black;
    transition: none;
}

@mixin easy-ui-pagination {
    $accent-color: $ck-red;

    :global .ant-pagination-prev .ant-pagination-item-link:not([disabled]) {
        @include black-square();
    }

    :global .ant-pagination-next .ant-pagination-item-link:not([disabled]) {
        @include black-square();
    }

    :global .ant-pagination-item {
        @include black-square();

        a {
            color: black;
        }

        &:hover {
            color: $accent-color;
            border-color: $accent-color;
            a {
                color: $accent-color;
            }
        }
    }

    :global .ant-pagination-item-active {
        color: $accent-color;
        border-color: $accent-color;
        a {
            color: $accent-color;
        }
    }

    :global .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        @include black-square();
    }

    :global
        .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
        .ant-select-selector {
        box-shadow: none;
        border-color: black;
    }

    :global .ant-pagination-jump-prev {
        svg {
            fill: black;
        }
    }

    :global .ant-pagination-jump-next {
        svg {
            fill: black;
        }
    }

    :global
        .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
        .ant-select-selector {
        border-right-width: 2px !important;
    }
}
