@import "../../easyui-components/easyui.scss";

.container {
    margin-right: 12px;
    position: relative;
}

.image_holder {
    width: 100%;
    height: 200px;
    overflow: hidden;
    border-radius: 2px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        user-select: none;
        object-fit: cover;
    }

    &:hover .overlay {
        opacity: 1;
    }
}

.overlay {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    opacity: 0;
    position: absolute;
    transition: opacity 0.4s;
    display: flex;
    align-items: center;
    justify-content: center;
}

$icon-size: 26px;

.eye_svg {
    font-size: $icon-size;
    svg {
        fill: white;
    }
}

.edit_svg {
    margin-left: 16px;
    font-size: $icon-size;
    display: inline-block;
    svg {
        fill: $ck-yellow;
    }
}

.delete_svg {
    margin-left: 16px;
    font-size: $icon-size;
    display: inline-block;
    svg {
        fill: $ck-red;
    }
}

.select_svg {
    margin-left: 16px;
    font-size: $icon-size;
    display: inline-block;
    svg {
        fill: rgb(39, 204, 88);
    }
}

.empty_interface {
    width: 100%;
    height: 100%;
    background-color: rgba(250, 250, 250, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px dashed #d9d9d9;
    border-radius: 2px;
    transition: border-color 0.25s;
    position: sticky;
    top: 0;
    backdrop-filter: blur(10px);
    z-index: 1;

    &:hover {
        border-color: $ck-red;
        cursor: pointer;
    }

    .hacky_white_border {
        position: absolute;
        bottom: -11px;
        left: -1px;
        width: calc(100% + 2px);
        height: 10px;
        background: rgba(255, 255, 255, 1);
    }
}

.hidden_input {
    width: 0;
    height: 0;
    visibility: hidden;
    position: absolute;
    z-index: -100;
    user-select: none;
}

.grid_wrapper {
    display: grid;
    grid-template-columns: repeat(4, 202px);
    grid-auto-rows: 202px;
    grid-gap: 10px;
    height: 100%;
    padding-bottom: 40px;
    box-sizing: border-box;
    overflow: scroll;
}

.grid_cell {
    position: relative;
    border: 1px solid lightgrey;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover .overlay {
        opacity: 1;
    }
}
