@import "../style.module.scss";

.page {
    background-color: $light-grey;
    padding-bottom: 20px;
    min-height: 100%;
}

.section {
    .list_header:first-child {
        margin-top: 0;
    }

    padding-bottom: 36px;
}
