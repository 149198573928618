@import "../../App.scss";

.card {
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;

    .title {
        font-weight: 600;
        font-size: 1rem;
        margin: 0.25rem 0rem;
        line-clamp: 2;
        line-height: 1.4;
    }
}
