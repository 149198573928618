@import "../../easyui-components/easyui.scss";

.club_preview {
    padding: 16px;
    border: 1px solid $light-border-color;
    cursor: pointer;

    &.completed {
        border: 1px solid $orange-light;
        background-color: #fff1d9;
    }

    &.completed .info_row {
        color: $ck-dark-red;
    }
}

.redeem_text {
    color: $orange-light;
    display: block;
    font-family: "Gotham-Medium";
    margin-bottom: 8px;
}

.title {
    color: black;
    font-size: 16px;
    font-family: Gotham-Medium;
}

.info_row {
    display: flex;
    justify-content: space-between;
    font-family: Gotham-Medium;
    font-size: 11px;
    color: #777;
    margin: 12px 0;
}

.icons_row {
    display: flex;
    align-items: center;
    gap: 2px;

    .line {
        background-color: #cccccc;
        flex-grow: 1;
        height: 1px;
    }
}
