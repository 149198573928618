@import "../style.module.scss";

.bottom_drawer {
    position: absolute;
    top: calc(100% + 10px);
    left: 0;
    transform: translateY(0);
    background-color: white;
    box-shadow: 0 -3px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
    transition: transform 0.5s;
    z-index: 2;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 25px 15px 46px;

    &.visible {
        transform: translateY(calc(-100% - 10px));
    }

    &.coupon_list {
        height: 80%;
        padding-bottom: 20px;
        overflow: scroll;
    }

    .close_text {
        font-family: Gotham-Book;
        font-size: 20px;
        color: $font-dark-grey;
        display: inline-block;
        cursor: pointer;
        align-self: flex-start;
    }

    .title {
        font-family: Gotham-Black;
        font-size: 32px;
        color: $font-dark-grey;
        line-height: 36px;
        margin-bottom: 20px;
    }

    .sub_title {
        font-family: Gotham-Book;
        font-size: 18px;
        color: black;
        margin-bottom: 32px;
    }

    button {
        flex-grow: 1;
        justify-content: center;
        font-size: 12px;
        padding: 10px 12px 7px !important;
        margin-bottom: 14px;
    }
}

.coupon_list {
    gap: 10px;
    display: flex;
    flex-direction: column;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0;
    pointer-events: none;
    z-index: 1;
    transition: opacity 0.5s;

    &.visible {
        opacity: 0.4;
        pointer-events: initial;
    }
}
