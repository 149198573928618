@import "../style.module.scss";

$selected-color: $orange-light;
$unselected-color: #777777;

.tabs {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 16px 16px 0;
    background-color: #f6f6f6;
    position: relative;
}

@mixin icons-configuration($color) {
    .circle_icon,
    .percent_icon {
        circle,
        line {
            transition: stroke 0.25s;
            stroke: $color;
        }
    }

    .gift_icon {
        path {
            transition: stroke 0.25s;
            stroke: $color;
        }
    }

    .ice_cream_icon {
        path {
            transition: fill 0.25s;
            fill: $color;
        }
    }
}

.tab_picker {
    padding: 12px 0 6px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    font-family: Gotham-Medium;
    transition: background-color 0.25s;
    position: relative;
    color: $unselected-color;

    &::before {
        transition: transform 0.25s;
        transform: scaleX(0);
        display: block;
        position: absolute;
        width: 100%;
        height: 4px;
        top: -2px;
        left: 0;
        background-color: $ck-red;
        border-radius: 4px;
        content: "";
    }

    @include icons-configuration($unselected-color);

    &.selected {
        @include icons-configuration($selected-color);
        background-color: white;
        color: black;
        box-shadow: 0px -4px 5px rgba(0, 0, 0, 0.14);
        &::before {
            transform: scaleX(1.02);
        }
    }
}

.hacky_holder {
    display: inline-block;
    position: relative;
}

.percent_icon {
    position: absolute;
    top: 23%;
    left: 28%;
}

.list_content {
    box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.14);
    display: flex;
    flex-direction: column;
    padding: 32px 16px;
    gap: 16px;
}

.section_title {
    font-family: "Gotham-Medium";
}
