.buttons_holder {
    display: flex;
    gap: 20px;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 4px;
    padding-top: 8px;
    z-index: 1;
}

.tooltip_container {
    display: flex;
    gap: 20px;
    justify-content: flex-start;
    align-items: center;
    z-index: 1;
}
