@import "../../easyui-components/easyui.scss";

.description_input_container {
    margin-right: 20px;
}

.fuel_container {
    margin-bottom: 20px;

    h3 {
        margin: 0;
    }
}

.form_container {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.inputs_container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.input_spacer {
    margin-left: 20px;
}

.label {
    display: flex;
    flex-wrap: wrap;
    max-width: 100px;
}

.label_text {
    display: block;
}

.select {
    min-width: 100px;
    display: block;
    width: 100px;
}
